.custom-modal {
  color: rgba(0, 0, 0, 0.56);

  button {
    white-space: normal;
  }

  mat-divider {
    margin: 10px 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    h1 {
      margin: 0;
      padding: 0;
      color: #3f4756;
    }

    mat-icon {
      background-color: transparent;
      background: transparent;
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
    }
  }
  .content {
    padding: 5px 24px;

    input[type="file"] {
      display: none;
    }

    h4 {
      font-size: 1rem;
    }

    .profile-photo {
      display: flex;
      justify-content: center;
      position: relative;

      img,
      .hover-image {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        object-fit: cover;
      }

      .hover-photo {
        max-width: 100px;
        height: auto;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
      }

      .hover-photo:hover .photo-overlay,
      .hover-photo:hover mat-icon,
      .hover-photo:hover p {
        opacity: 1;
        transition: ease-in-out 0.4s;
      }

      .hover-photo:hover mat-icon {
        top: 43%;
      }

      .hover-photo:hover p {
        top: 60%;
      }

      .photo-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 100%;
        transition: ease-in-out 0.4s;
        opacity: 0;
      }

      mat-icon,
      p {
        position: absolute;
        top: -100px;
        transition: ease-in-out 0.4s;
        opacity: 0;
        color: white;
      }
    }

    label {
      margin: 0 10px 0 0;
    }

    .mat-expansion-panel-body,
    .mat-select-min-line {
      color: rgba(0, 0, 0, 0.56);
    }
  }

  .mat-dialog-actions {
    padding: 1rem 0;
  }
}
