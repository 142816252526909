$primary: #c41f1a;
$secondary: #3f3d3d;

$success: #00B645;
$danger: #dc3545;
$warning: #ffc107;


$white: #ffffff;

$black: #000000;

$text-color: #656;
