body {
  //font-family: "netto_otregular","Yanone Kaffeesatz",Verdana,sans-serif!important;
  background: #f2f2f2;
  font-family: 'Open Sans', sans-serif;

  main {
    //padding-top: 25px;
    min-height: 100vh;

    .mat-sidenav-content {
      background: white;
    }
  }

  :focus {
    outline: 0 !important;
  }

  table td {
    vertical-align: middle !important;
  }

  .mat-h2 {
    color: #455a64;
  }
}

.breadcrumb {
  font-size: 12px;
  padding: 0;
  border-radius: 0;
  background: transparent;
  margin: 0px;
}
