.mat-table {
  .td.mat-cell,
  td.mat-footer-cell,
  th.mat-header-cell {
    padding-bottom: 0px;
  }

  td.mat-cell,
  th.mat-header-cell {
    padding: 0.3rem 0.4rem;
  }
}

table {
  width: 100%;

  .mat-cell {
    @extend .ellipsis;
  }

  .mat-column-actions {
    padding-left: 1rem !important;
    min-width: 80px;
    width: 80px;
  }

  tr {
    position: relative;

    .hidden-status {
      position: absolute;
      height: 40px;
      width: 10px;
      left: 0;
      top: 5px;
      cursor: pointer;
      transition: all ease-in-out 0.4s;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 1;

      span {
        display: none;
        color: transparent;
        transition: color ease-in-out 0.4s;
      }

      &:hover {
        width: 150px;
        transition: all ease-in-out 0.5s;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        span {
          display: block;
          color: white;
          transition: color ease-in-out 0.3s;
        }
      }
    }

    &:last-child {
      td {
        border-bottom-width: 0;
      }
    }
  }
}
