@import 'variables';

.input-group-append {
  button {
    border-color: $primary !important;
    color: $primary !important;

    &:not(.btn-outline-secondary) {
      margin: 0 5px;
    }

    &:hover,
    &:focus,
    &:active {
      color: $white !important;
      background-color: $primary !important;
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus,
  .dropdown-item:active {
    color: $white !important;
    background-color: $primary !important;
  }
}

.btnOito{
  background-color: $primary;
  color: $white;
  float: right;
}

.mat-success {
  background-color: $success;
  color: $white !important;
}
