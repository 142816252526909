//variáveis globais
@import 'variables';

//importar tema
@import 'theme/all';

//importação da estrutura de css
@import 'components/all';

//fonte
@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.ql-align-center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ql-align-right {
  justify-content: right;
  text-align: right;
}

.ql-align-left {
  justify-content: left;
  text-align: left;
}
