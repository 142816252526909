//--------------------------------------------------------
//--------------------------------------------------------
//-------------------------BOOTSTRAP----------------------
//--------------------------------------------------------
//--------------------------------------------------------

$primary: #c41f1a; //mat_color($app-theme-primary);
$secondary: #3f3d3d; //mat-color($app-theme-accent);
$danger: mat-color($app-theme-warn);

// importar bootstrap
@import "~bootstrap/scss/bootstrap.scss";
