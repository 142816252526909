@import 'variables';

.default-snackbar {
  background: $secondary;
  .mat-simple-snackbar-action {
    color: $white !important;
  }
}

.danger-snackbar {
  background: $danger;
  .mat-simple-snackbar-action {
    color: $white !important;
  }
}

.success-snackbar {
  background: $success;
  .mat-simple-snackbar-action {
    color: $white !important;
  }
}

.warning-snackbar {
  background: $warning;
  color: $black !important;
  .mat-simple-snackbar-action {
    color: $black !important;
  }
}
