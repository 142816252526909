@import 'all';
@import '~quill/dist/quill.bubble.css';

body,
html {
  color: $text-color;
}

mat-card-header:not(.header-padrao) {
  padding: 15px 20px 4px 20px !important;
}

.no-padding {
  padding: 0 !important;
}

.sidebar-accordion {
  .mat-expansion-panel-content {
    > .mat-expansion-panel-body {
      padding: 0 !important;
    }
  }

  .mat-expansion-panel-header {
    .mat-content {
      overflow: unset !important;
    }

    &:hover {
      .mat-expansion-indicator:after {
        color: $secondary;
      }
    }

    .mat-expansion-indicator:after {
      color: $white;
    }
  }
}

.forum {
  .pergunta-container {
    .mat-content {
      padding: 10px 0;
    }
  }
}

.mat-card {
  .cotacao-filtros {
    .mat-form-field-wrapper {
      padding: 0;
    }
  }
}

tr.mat-header-row {
  height: 33px;
}

.mat-drawer-content {
  height: 100vh !important;
}

.mat-drawer-side {
  border: 0;
}

.ps__rail-y {
  z-index: 10 !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-footer-cell {
  font-weight: 600;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
