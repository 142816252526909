mat-card {
  background-color: #fff;
  border: 1px solid #e4e9f2;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: #555 !important;
  font-family: Open Sans, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 1.875rem;
  scrollbar-face-color: #e4e9f2;
  scrollbar-track-color: #f7f9fc;
  padding: 0 !important;

  mat-card-header {
    background-color: whitesmoke;
    padding: 1rem 1.25rem;
    border-bottom: 1px solid #edf1f7;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .mat-card-header-text {
      margin: 0;
    }

    .action-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .mat-card-title {
      margin: 0 !important;
      color: #3f3f3f;
      font-family: Open Sans, sans-serif;
      font-size: 0.9375rem !important;
      font-weight: 600;
      line-height: 1.5rem;

      .action-buttons-header {
        button {
          margin: 0 5px;
          width: 64px;
        }
      }
    }
  }

  .mat-card-content {
    flex: 1;
    -ms-flex: 1 1 auto;
    overflow: auto;
    border-radius: 10px;
    padding: 1rem 1.25rem;
    position: relative;
    margin: 0 !important;
    scrollbar-face-color: #e4e9f2;
    scrollbar-track-color: #f7f9fc;
  }

  .mat-card-actions {
    padding: 0 !important;
    border-top: 1px solid #edf1f7;
    border-bottom-left-radius: 0.17rem;
    border-bottom-right-radius: 0.17rem;
    margin: 0 !important;
  }
}

@media only screen and (max-width: 767.98px) {
  mat-card {
    mat-card-header {
      .action-header {
        flex-direction: column;

        .action-buttons-header {
          margin-top: 10px;
        }
      }
    }
  }

  .action-buttons-header {
    button {
      margin: 5px;
    }
  }
}
